<template>
  <slick-carousel class="default-slick-carousel freelancers-container freelancers-grid-layout left-align-slick"
       :options="slickOptions"
  >

    <!--Campaign -->
    <div v-for="(campaign, index) in sortedCampaigns"
         :key="index" class="freelancer">
      <!-- Overview -->
      <div class="freelancer-overview">
        <div class="freelancer-overview-inner">
          <!-- Avatar -->
          <div class="freelancer-avatar">
            <router-link :to="{name: 'Campaign', params:{'campaignId':campaign.id}}">
              <img :src="campaign.image || charityInfo.cover_photo" alt="">
            </router-link>
          </div>
          <!-- Name -->
          <div class="freelancer-name padding-right-25 padding-left-25">
            <h4>
              <router-link :to="{name: 'Campaign', params:{'campaignId':campaign.id}}"
              >{{campaign.name}}
              </router-link>
            </h4>
          </div>
        </div>
      </div>

      <!-- Details -->
      <div class="freelancer-details">

        <a v-if="campaign.progress && campaign.goal" href="#" class="primary-color fw-600">{{completePercent(campaign)}}%</a>
        <span
            v-if="campaign.progress && campaign.goal">${{(campaign.goal / 100) | formatMoney}}</span>

        <div v-if="campaign.progress && campaign.goal" class="account-indicators">
          <!-- Indicator -->
          <div class="indicator">
            <div class="indicator-bar"><span :style="{ width: completePercent(campaign)+'%'}"></span></div>
          </div>

        </div>
        <div v-if="campaign.progress" class="freelancer-details-list">
          <ul>
            <li><strong>${{(campaign.progress.donated / 100) | formatMoney}}</strong>Donated</li>
            <li v-if="campaign.goal"><strong>${{(campaign.goal / 100) | formatMoney}}</strong>Goal</li>
            <li><strong>{{campaign.progress.donors}}</strong>Donors</li>
          </ul>
        </div>
        <router-link :to="{name: 'Campaign', params:{'campaignId':campaign.id}}"
                     class="button button-sliding-icon ripple-effect"
        >View Campaign <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i>
        </router-link>
      </div>
    </div>
    <!-- Campaign / End -->

  </slick-carousel>
</template>

<script>
import SlickCarousel from '@/components/SlickCarousel/SlickCarousel';

  export default {
    name: 'CampaignSlider',
    components: {
      SlickCarousel
    },
    props: {
      sortedCampaigns: null,
      charityInfo: null,
      details: {
        type: String,
        'default': 'default'
      }
    },
    methods: {
      completePercent(campaign) {
        return Math.round(parseFloat(campaign.progress.donated / 100) / parseFloat(campaign.goal / 100) * 100);
      },
    },
    //
    data(){
      return {
        slickOptions: {
          infinite: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
          prevArrow: `<button type="button" class="slick-prev">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-chevron-left">
<path fill="currentColor" d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"></path>
</svg></button>`,
          nextArrow: `<button type="button" class="slick-next">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-chevron-right">
<path fill="currentColor" d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path>
</svg></button>`,
          adaptiveHeight: true,
          // variableWidth: true,
          responsive: [
            {
              breakpoint: 1292,
              settings: {
                dots: true,
                arrows: false
              }
            },
            {
              breakpoint: 993,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true,
                arrows: false
              }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: false
              }
            }
          ]
        },
      }
    }
  }
</script>
